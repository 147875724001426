var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center"},[_c('StaticLock',{on:{"conditionPass":_vm.handleConditionPass}}),_c('div',{staticClass:"langtoggle"},[_c('LanguageSwitch')],1),(_vm.coverImage !== '')?_c('div',{staticClass:"header-cover",style:({
    'background-image': `url('${_vm.coverImage}')`,
    width: '100%',
    'background-size': 'cover',
    'background-position': 'center center',
  })}):_vm._e(),_c('CCard',{staticClass:"p-3",style:({ top: _vm.cardTop })},[_c('CCardBody',{staticClass:"text-center"},[_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('div',{staticClass:"logoImg",style:({
          'background-image': `url('${_vm.logo}'), url('${_vm.noImg}')`,
          'background-size': 'cover',
          'background-position': 'center center',
        })})]),_c('h2',{staticClass:"font-weight-bold mt-3"},[_vm._v(" "+_vm._s(_vm.shopName)+" ")]),_c('h6',{staticClass:"text-grey mb-4"},[_vm._v(_vm._s(_vm.branchName)+" ")]),_c('span',{staticClass:"h5 font-weight-normal"},[_c('i',{staticClass:"fi fi-rr-utensils"}),_vm._v(" "+_vm._s(_vm.tableName)+"   |   "),_c('i',{staticClass:"fi fi-rr-user"}),_vm._v(" "+_vm._s(_vm.pax)+" "+_vm._s(_vm.$t('guest'))+" ")]),_c('br'),_c('CButton',{staticClass:"mt-5 btn-startorder p-3",staticStyle:{"font-size":"20px","color":"white"},attrs:{"disabled":_vm.loading,"block":""},on:{"click":_vm.openTable}},[(_vm.loading)?_c('CSpinner',{staticClass:"mb-1",attrs:{"component":"span","size":"sm","aria-hidden":"true"}}):_vm._e(),_vm._v("   "+_vm._s(_vm.$t('startOrder'))+" ")],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('CButton',{staticClass:"mt-3 btn-square-home",attrs:{"disabled":_vm.loading},on:{"click":_vm.goToCart}},[_c('i',{staticClass:"fi fi-rr-shopping-basket",staticStyle:{"font-size":"30px"}}),_c('br'),_vm._v(" "+_vm._s(_vm.$t('orderItemNav'))+" ")])],1),_c('div',{staticClass:"col-6"},[_c('CButton',{staticClass:"mt-3 btn-square-home",attrs:{"disabled":_vm.loading},on:{"click":() => { _vm.callStaffModal = true }}},[_c('img',{staticClass:"img-fluid pb-2",staticStyle:{"width":"34px","margin-bottom":"6px"},attrs:{"src":require("@/assets/icons/bell.png")}}),_c('br'),_vm._v(" "+_vm._s(_vm.$t('callStaff'))+" ")])],1)]),_c('small',{attrs:{"hidden":""}},[_vm._v(_vm._s(_vm.timestamp))]),_c('div',{staticClass:"mt-5 mb-5"},[_c('span',[_vm._v(" Powered by "),_c('img',{staticClass:"img-fluid ml-1",attrs:{"src":"/logo.png","width":"30%","alt":""}})])])],1)],1),_c('call-staff',{attrs:{"callStaffModal":_vm.callStaffModal},on:{"update-status":function($event){_vm.callStaffModal = false}}}),_c('div',{staticClass:"footer-home"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }