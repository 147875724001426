<template>
    <transition name="fade-in">
        <div v-if="!verified && qrType == 'STATIC'">
            <div class="qr-static-container">
                <!-- Lock Pin -->
                <template v-if="showSecion == 0">
                    <div class="qr-static-modal">
                        <template v-if="incorrectAttempt < 5">
                            <i style="color: var(--bg-color-theme);font-size: 40px;" class="fi fi-rr-lock"></i>
                            <h5 class="text-bold">{{ $t('enterPasswordToAccessMenu') }}</h5>
                            <span class="text-description" v-html="$t('customersCanGetPasswordFromStaff')"></span>
                            <div class="row pl-5 pr-5 pt-2 pb-2 text-center">
                                <div v-for="(value, index) in PINModel" :key="index" class="col-3 p-1">
                                    <input type="tel" v-model="PINModel[index]" :ref="'pin' + index"
                                        class="text-center otp-input" maxlength="1" @input="moveToNext(index)"
                                        @keydown.backspace="moveToPrevious(index, $event)" pattern="[0-9]"
                                        inputmode="numeric"
                                        onkeypress="return event.charCode >= 48 && event.charCode <= 57" />
                                </div>
                                <span v-if="alert" class="text-danger mt-2 col-12">{{ $t('inValidPIN') }}</span>
                            </div>
                            <div class="mt-2 mb-2">
                                <CButton @click="verifyOrderAble()" block class="btn-color-theme"
                                    :disabled="loading || !isPINComplete">
                                    <CSpinner v-if="loading" class="mb-1" component="span" size="sm"
                                        aria-hidden="true" />
                                    &nbsp;
                                    {{ $t('confirm') }}
                                </CButton>
                            </div>
                        </template>
                        <template v-else>
                            <i style="color: var(--bg-color-theme);font-size: 40px;" class="fi fi-rr-lock"></i>
                            <h5 class="text-bold">{{ $t('lockUserPIN') }}</h5>
                            <span class="text-description"> {{ $t('tryAgainIn') }} {{ lockEnd }}</span>
                            <div class="mt-2 mb-2">
                                <CButton @click="refreshVerification()" block class="btn-color-theme "
                                    :disabled="loading">
                                    <CSpinner v-if="loading" class="mb-1" component="span" size="sm"
                                        aria-hidden="true" />
                                    &nbsp;
                                    {{ $t('refresh') }}
                                </CButton>
                            </div>
                        </template>
                    </div>
                </template>
                <!-- Table Not Available -->
                <template v-if="showSecion == 1">
                    <div class="qr-static-modal">
                        <i style="color: var(--bg-color-theme);font-size: 40px;" class="fi fi-rr-lock"></i>
                        <h5 class="text-bold">{{ $t('tableNotAvailable') }}</h5>
                        <span class="text-description">{{ $t('contactStaffToOpenTable') }}</span>
                        <div class="mt-2 mb-2">
                            <CButton @click="refreshVerification()" block class="btn-color-theme " :disabled="loading">
                                <CSpinner v-if="loading" class="mb-1" component="span" size="sm" aria-hidden="true" />
                                &nbsp;
                                {{ $t('refresh') }}
                            </CButton>
                        </div>
                    </div>
                </template>
                <!-- Outside Restaurant -->
                <template v-if="showSecion == 2">
                    <div class="qr-static-modal">
                        <i style="color: var(--bg-color-theme);font-size: 40px;" class="fi fi-rr-marker"></i>
                        <h5 class="text-bold">{{ $t('outOfRestaurantRange') }}</h5>
                        <span class="text-description">{{ $t('phoneIsMoreThan100MetersAwayFromRestaurant') }}</span>
                        <div class="mt-2 mb-2">
                            <CButton @click="refreshVerification()" block class="btn-color-theme " :disabled="loading">
                                <CSpinner v-if="loading" class="mb-1" component="span" size="sm" aria-hidden="true" />
                                &nbsp;
                                {{ $t('refresh') }}
                            </CButton>
                        </div>
                    </div>
                </template>

                <!-- Kitchen Close -->
                <template v-if="showSecion == 3">
                    <div class="shop-close-modal">
                        <div>
                            <img src="/img/shopClose.png" width="55%">
                            <template v-if="!isShopCloseToday">
                                <h4 class="text-bold mt-4 mb-2">{{ $t('restaurantIsCurrentlyClosed') }}</h4>
                                <span class="text-description">{{ $t('restaurantOpenHours') }} {{ openTime()
                                    }}</span><br>
                            </template>
                            <template v-else>
                                <h4 class="text-bold mt-4 mb-2">{{ $t('shopIsClosedToday') }}</h4>
                                <span class="text-description">{{ $t('sorryForInconvenient') }}</span><br>
                            </template>
                            <div class="fixed-bottom pb-3">
                                <CButton v-if="receiptObjectId" @click="goToCart()" class="btn-color-theme mb-5"
                                    :disabled="loading">
                                    <CSpinner v-if="loading" class="mb-1" component="span" size="sm"
                                        aria-hidden="true" />
                                    &nbsp;
                                    {{ $t('checkOrderedItem') }}
                                </CButton><br>
                                <span style="color:#494848;">Powered by </span>
                                <img class="ml-1" src="/img/SilomPOS_logo.png" width="20%">
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </transition>
</template>
<script>
import pos from '@/services/pos'
import '@/util/menu.css'
import { mapGetters } from 'vuex'
import poscrm from '@/services/poscrm'

export default {
    components: {

    },
    data() {
        return {
            PINModel: ['', '', '', ''],
            loading: false,
            showSecion: 0,
            alert: false,
            alertText: '',
            firstLoad: true,
            verified: true,
            pageLoading: false,
            orderShopSetting: {},
            qrType: '',
            conditionPass: false,
            incorrectAttempt: 0,
            isShopCloseToday: false,
        }
    },
    computed: {
        ...mapGetters(['shopLineOA']),
        tableObjectId() {
            let objectId = this.$route.query.tableObjectId || null
            return objectId
        },
        uid() {
            return localStorage.getItem('uid') || null
        },
        shopObjectId() {
            return localStorage.getItem('shopObjectId') || null
        },
        shop() {
            return this.shopLineOA.find(
                (detail) => detail.objectId === this.shopObjectId
            )
        },
        isPINComplete() {
            return this.PINModel.every(value => value !== '');
        },
        lockEnd() {
            let time = parseInt(localStorage.getItem('lockEnd'))
            return new Date(time).toLocaleTimeString()
        },
        receiptObjectId() {
            return localStorage.getItem('receiptObjectId') || null
        },
    },
    created() {
        this.getOrderShopSetting()
        this.checkIncorrectAttempt()

    },
    methods: {
        checkIncorrectAttempt() {
            this.incorrectAttempt = parseInt(localStorage.getItem('incorrectAttempt')) || 0
            let lockEnd = localStorage.getItem('lockEnd') || null
            let currentTime = new Date().getTime()
            if (lockEnd !== null) {
                if (currentTime > lockEnd) {
                    this.incorrectAttempt = 0
                    localStorage.removeItem('incorrectAttempt')
                    localStorage.removeItem('lockEnd')
                }
            }
        },
        async getOrderShopSetting() {
            const uid = `${localStorage.getItem('uid')}`
            const shopObjectId = `${localStorage.getItem('shopObjectId')}`

            let params = {
                shopObjectId: shopObjectId,
            }
            try {
                await poscrm({
                    method: 'get',
                    url: '/api/v2.1/' + uid + '/OrderShopSetting',
                    params: params,
                }).then((res) => {
                    this.orderShopSetting = res?.data?.data[0] || {}
                    this.qrType = this.orderShopSetting?.qrType
                    if (this.qrType == 'STATIC') {
                        this.verifyOrderAble()
                    }
                })
            } catch (error) {
                throw new Error(error)
            }
        },
        openTime() {
            const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
            const todayIndex = new Date().getDay();
            const todaySchedule = this.orderShopSetting.kitchenTime[daysOfWeek[todayIndex]];
            this.isShopCloseToday = todaySchedule.closed
            return `${todaySchedule.openTime} - ${todaySchedule.closeTime}` || ''
        },
        checkLocation() {
            let shopLocation = this.shop.location; // Example: lat:13.742541, lng:100.5856554
            // Get user's current location using the Geolocation API
            navigator.geolocation.getCurrentPosition((position) => {
                let userLocation = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };

                // Calculate the distance between shop and user
                let distance = this.calculateDistance(shopLocation, userLocation);

                // Check if the user is within 100 meters
                if (distance <= 100) {
                    this.conditionPass = true
                    this.verified = true;
                } else {
                    this.showSecion = 2;
                    this.verified = false;
                }
                this.loading = false
            }, (error) => {
                this.handleGeolocationError(error);
            });
        },

        handleGeolocationError(error) {
            // Handle different error codes from the Geolocation API
            switch (error.code) {
                case error.PERMISSION_DENIED:
                    alert("Location permission denied. Please enable location services.");
                    break;
                case error.POSITION_UNAVAILABLE:
                    alert("Location information is unavailable.");
                    break;
                case error.TIMEOUT:
                    alert("Request to get user location timed out.");
                    break;
                case error.UNKNOWN_ERROR:
                    alert("An unknown error occurred.");
                    break;
            }

            console.error('Error getting user location', error);
            this.showSecion = 2;
            this.verified = false;
        },

        // Haversine formula to calculate the distance between two coordinates
        calculateDistance(shopLocation, userLocation) {
            const R = 6371e3; // Radius of Earth in meters
            const φ1 = this.degToRad(shopLocation.lat); // Convert latitude to radians
            const φ2 = this.degToRad(userLocation.lat); // Convert latitude to radians
            const Δφ = this.degToRad(userLocation.lat - shopLocation.lat);
            const Δλ = this.degToRad(userLocation.lng - shopLocation.lng);

            const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
                Math.cos(φ1) * Math.cos(φ2) *
                Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

            const distance = R * c; // Distance in meters
            return distance;
        },

        // Helper function to convert degrees to radians
        degToRad(degrees) {
            return degrees * (Math.PI / 180);
        },
        async verifyOrderAble() {
            this.alert = false;
            this.loading = true;
            // Get the PIN from localStorage or entered PIN
            let pinString = localStorage.getItem('pin') || this.PINModel.join('');
            if (pinString == '') {
                pinString = null
            }

            try {
                // Call the orderAble API
                const response = await pos({
                    method: 'get',
                    url: '/api/v3.0/shop/order/able',
                    headers: {
                        shopObjectId: this.shopObjectId,
                        tableObjectId: this.tableObjectId,
                        pin: pinString
                    }
                });
                const isAble = response.data?.data?.success || false;
                if (isAble) {
                    localStorage.setItem('pin', pinString);
                    let condition = this.orderShopSetting?.staticQRCondition;
                    if (condition?.locationRequired) {
                        this.loading = true
                        this.checkLocation()
                    } else {
                        this.conditionPass = true
                        this.verified = true
                        this.loading = false
                    }
                } else {
                    this.verified = false
                    localStorage.removeItem('pin')
                    if (this.firstLoad) {
                        this.handleErrorCode(response.data.error.code)
                    } else {
                        this.alert = true;
                        this.incorrectAttempt = parseInt(localStorage.getItem('incorrectAttempt')) || 0
                        this.incorrectAttempt += 1
                        localStorage.setItem('incorrectAttempt', this.incorrectAttempt)
                        if (this.incorrectAttempt > 4) {
                            let currentTime = new Date().getTime()
                            let lockEnd = currentTime + (5 * 60 * 1000)
                            localStorage.setItem('lockEnd', lockEnd)
                        }
                    }
                    this.firstLoad = false
                }
                this.loading = false;
            } catch (error) {
                console.error('Error calling the orderAble API:', error);
                this.loading = false;
            }
        },
        handleErrorCode(code) {
            switch (code) {
                case 4003:
                    this.showSecion = 1
                    break;
                case 4004:
                    this.showSecion = 0
                    break;
                case 4002:
                    this.showSecion = 3
                    break;
                default:
                    break;
            }
        },
        moveToNext(index) {
            if (this.PINModel.every(item => item !== '')) {
                this.verifyOrderAble()
                return
            }
            if (this.PINModel[index].length === 1 && index < this.PINModel.length - 1) {
                this.$refs[`pin${index + 1}`][0].focus()
            }
        },
        moveToPrevious(index, event) {
            if (event.key === 'Backspace' && index > 0 && !this.PINModel[index]) {
                this.$refs[`pin${index - 1}`][0].focus()
            }
        },
        refreshVerification() {
            this.loading = true
            window.location.reload()
        },
        goToCart() {
            this.loading = true
            this.$router.push('/dine-in/mycart')
        },
    },
    watch: {
        conditionPass(newVal) {
            if (newVal && this.qrType === 'STATIC') {
                this.$emit('conditionPass', true); // Emit 'verified' event to parent when verified is true
            }
        }
    },
}
</script>
<style>
/* Fade-in transition classes */
.fade-in-leave-active {
    transition: opacity 0.5s ease;
}

.fade-in-leave-to {
    opacity: 0;
}
</style>
